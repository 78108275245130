<template>
  <div>
    <div style="margin-bottom: 25px;">
      <van-cell-group>
        <!-- <van-cell title="头像">
          <template #right-icon>
            <van-image round
              width="3rem"
              height="3rem"
              :src="list.HeadPicUrl" />

          </template>
        </van-cell> -->
        <van-cell title="姓名"
          :value="list.Name" />
        <van-cell title="性别"
          :value="list.SexDesc" />
        <!-- <van-cell title="年龄"
          :value="list.Name" /> -->
        <van-cell title="疾病类型"
          :value="list.DiseaseCircs" />
        <van-cell title="出生日期"
          :value="list.Birthday" />
        <van-cell title="身份证号"
          :value="list.IdNumber"
          title-class="title-class"
          value-class="value-class" />
        <van-cell title="所在社区"
          :value="list.OrganName" />
        <van-cell title="监护人"
          :value="list.GuardianName " />
        <van-cell title="监护人电话"
          :value="list.GuardianTel" />
      </van-cell-group>
      <div style="width:95%;margin:10px auto;bottom: 0; position: fixed;padding: 10px"
        v-if="!bjShow">
        <van-button style="width:100%;"
          round
          type="info"
          @click="bianji(list)">编辑监护人</van-button>
      </div>
    </div>
    <div v-if="bjShow">
      <van-cell-group style="margin-bottom: 55px;">
        <van-field v-model="jianhu.GuardianName"
          required
          label="监护人姓名"
          placeholder="请输入监护人姓名" />
        <van-field v-model="jianhu.GuardianTel"
          required
          label="监护人电话"
          placeholder="请输入监护人联系电话" />
      </van-cell-group>
      <div style="width:95%;margin:10px auto;bottom: 0; position: fixed;padding: 10px">
        <van-button style="width:100%;"
          round
          type="info"
          @click="update(list)">保存</van-button>
      </div>
    </div>

  </div>
</template>
<script>
import { WeGetElderlyBaseInfo, WeBindSaveElderly } from "@/api/Pension";
import jiatingVue from '../wangge/jiating.vue';
import { Toast } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
export default {
  data () {
    return {
      dataform: {},
      list: [],
      jianhu: {
        GuardianName: "",
        GuardianTel: "",
      },
      bjShow: false,
      OpenID: '',
    }
  },
  created () {
    this.laorenXx();
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    console.log(getOpenId())
  },
  methods: {
    laorenXx: function () {
      this.OpenID = getOpenId();
      WeGetElderlyBaseInfo({ openID: this.OpenID }).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.list = res.data.data;
        } else {
          Toast.fail(res.data.msg);
        }
      })
    },
    // update: function () {
    //   this.$router.push("/pension/xinXiBianJi/");
    // }
    bianji: function (row) {
      console.log(row);
      this.bjShow = true
      // this.jianhu.HId = row.HId
      this.jianhu.GuardianName = row.GuardianName
      this.jianhu.GuardianTel = row.GuardianTel
    },
    update: function (row) {
      console.log(row);
      this.jianhu.HId = row.HId
      // this.jianhu.GuardianName = row.GuardianName
      // this.jianhu.GuardianTel = row.GuardianTel
      console.log(this.jianhu.GuardianName);
      if (this.jianhu.GuardianName == '' && this.jianhu.GuardianName == null) {
        Toast.fail("请输入监护人");
      } else if (this.jianhu.GuardianTel == '' && this.jianhu.GuardianTel == null) {
        Toast.fail("请输入监护人电话");
      } else {
        WeBindSaveElderly(this.jianhu).then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            Toast.success("保存成功");
            this.bjShow = false
            this.laorenXx();
          } else {
            Toast.fail(res.data.msg);
          }
        })
      }
    }
  }
}  
</script>
<style scoped>
.title-class {
  min-width: 30% !important;
}
.value-class {
  min-width: 60% !important;
}
.block {
  width: 100%;
  background: #fff;
  margin: 15px 4%;
}
.block .title {
  text-align: center;
  padding: 8px 0px;
}
.van-cell {
  font-size: 18px;
  font-weight: 540;
  font-family: -webkit-pictograph;
  height: 56px;
}
</style>